import React from 'react';
import Layout from '../components/layout/layout';

const NotFoundPage = () => {
    return (
        <Layout metaTitle="404: Not found">
            <h1>Not Found</h1>
            <p>The page you requested could not be found. Try to use the navigation above to locate the post.</p>
        </Layout>
    );
};

export default NotFoundPage;
